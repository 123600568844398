/**
 * 激励视频的配置文件 具备激励视频sdk的马甲都需要在这里添加bundleID。
 * @type {string[]}
 */
export const newBundle = [
    'com.pieceClothes',
    'aizhangdian.gov.cn',
    'www.kuaizike.com',
    'com.CSHL.Draw',
    'com.zheng.VoiceGarbage',
    'com.huajiang.www',
    'com.CSHL.Calligraphy',
    'com.LocalDiary.LocalDiary',
    'com.chuangqish.diaoqianyaner',
    'com.readpacket.ke',
    'com.huaiqangu.youshihuahuaqiangu',
    'com.rui.jinmeirui',
    'com.shuo.liulishuo',
    'cn.haa.fonfApp',
    'com.cooperationcircle.CooperationCircle',
    'com.LD-landscape',
    'com.jackyugames.piraterunaway',
    'com.jackyugames.piraterunawa',
    'com.sofamall.www',
    'com.jr.pianyiju',
    'com.guanjia.qianyan',
    'com.tc.killer',
    'com.game.FSaveToHang',
    'com.ccc.SweetDream12',
    'com.lemonCoder.OASIstore',
    'com.find.answerColor',
    'com.Xiang.GMStore',
    'com.Sheng.DZPStore',
    'com.JQCZ.JQCZGrowQuietly',
    'ceom.masd.FcsrTextWire',
    'aue.uavehicle',
    'com.Hong.CYStore',
    'cn.tao.TCApp',
    'com.xiangcunshu.com',
    'com.wang.xianhua',
    'com.shangzezhushou.cuteBabyProject',
    'com.AntigueProject.fandieAntique',
    'Ceramic.CeramicProduct',
    'com.sing.setitevey',
    'com.wang.xianhua',
    'com.cwz.riddle',
    'com.qinghuachuju',
    'oubsk38.chujianbaitai',
    'Forest.cake.com',
    'com.HuaKaGo',
    'tuogao7364qjnup.xiaopangwenwan',
    'com.lifegood.AForFourteenth',
    'zhengji9868039.namokongjian',
    'com.yuo.School.BuyBooks',
    'com.lvchiqibao.com',
    'com.CCAA.CarConsumption',
    'com.qingqingWanou.com',
    'com.qianyanChaozai.com',
    'com.qianyanYishu.com',
    'com.qianqianAimiao.com',
    'com.qianqianShenghuo.com',
    'com.qianyanChaju.com',
    'com.qianshuJiafang.com',
    'com.qianyanJiaju.com',
    'com.qianyanNanzhuang.com',
    'com.qianyanLvzhong.com',
    'com.qianyanWenxue.com',
    'com..qianyanShipin.com',
    'com.qianyanBiaozhong.com',
    'com.qianyanLipin.com',
    'com.qianchengChehu.com',
    'com.qianyanWazhuang.com',
    'com.qianyanFushi.com',
    'com.qianyanChudian.com',
    'com.qianyanHuayi.com',
    'com.qianyanNeida.com',
    'com.qianyanHaowu.com',
    'mengchonglingshifuan.com',
    'com.qianxun.SportBingQiuEr',
    'com.haochikouqiang.com',
    'wanwanju.com',
    'meiyouxihu.com',
    'carrec.CardRecords',
    'com.kefuxihu.com',
    'com.jiateming.com',
    'com.jindaneicun.com',
    'temeiDianshan.com',
    'com.NIETY.NiceTasty',
    'com.siyunCansibei.com',
    'com.XXMM.XiaoMiCooking',
    'com.xychuangpin.com',
    'com.anyangMaotan.com',
    'com.lidongShandiche.com',
    'pinghengche.com',
    'youneiyi.com',
    'youxiaokaiguan.com',
    'shugoushuidai.com',
    'com.shoujizhijia.com',
    'com.qianliyan.com',
    'qiushanqianbao.com',
    'com.hailangyongmao.com',
    'qianyunZuxie.com',
    'duaiYingerchuang.com',
    'com.jiazimei.com',
    'com.ziniaoku.com',
    'com.zidanZheyangsan.com',
    'femianbuJinghua.com',
    'youguaChuju.com',
    'banshengShouzuo.com',
    'fangsaixiu.com',
    'com.hexixiongzhen.com',
    'weiweinvwa.com',
    'lushangSaiting.com',
    'runshachahu.com',
    'gaoguoWeiyang.com',
    'guai.hunanguaiguaisheng.s',
    'strongduck.com',
    'com.guangyingbangongshebei.com',
    'Weishimepig.com',
    'xiaokeaimei.com',
    'tutushu.ts',
    'OPKOL.COM..OPKOL',
    'ermeiforgirl.com',
    'dongmanbaoliji.baoliji',
    'yausjdjakskak.yausjdjakskak',
    'opogsuogf.opogsuogf',
    'yayyshdajjaaa.yayyshdajjaaa',
    'yyahshdjajsda.yyahshdjajsda',
    'yichongZhikang.com',
    'yayshdjauausdh.yayshdjauausdh',
    'agsydhjajdaasda.agsydhjajdaasda',
    'mantoujiazu.mantou',
    'com.screenshotassistant.app',
    'com.dianjisuoyoushuliang.cn',
    'wujixiaojie.wuji',
    'com.cn.Organize.OrganizeBooks',
    'kejigeji.geji',
    'houyanMONEY.houyan',
    'com.dakeya',
    'com.worksuiji.v',
    'com.qinxin.PlantNotes',
    'com.geaoyutu',
    'com.record.StudyNotes',
    'com.pingshanhuwai.com',
    'com.kalulichengebi',
    'com.laifujiyi',
    'com.xiaogouzideguanlijilu.cn',
    'com.record.DietRecord',
    'com.renwushifoujieshujilu.cn',
    'com.meirijianguojilu.cn',
    'lianai.LoveStory',
    'com.hepengyouyiqiqulvxing.cn',
    'xiaoxiongzinv.xiongzinv',
    'com.shuiguodajiating',
    'com.record.Amahil',
    'daird.DailyRdNote',
    'pinneida.com',
    'com.xiaoijgeji',
    'com.jiluhepengyoudeshenfenxinxi.cn',
    'sangnamengjiji.sangnaji',
    'yangmawaitao.com',
    'house.FangJiXiaoBen',
    'duoliLiangxie.com',
    'com.shenyuanNanxie.com',
    'teyanxiangzhuang.com',
    'com.duosejisuanqi.cn',
    'lihuo.GoodsBill',
    'com.xiaolutupianbianji.cn',
    'ruikajiafang.com',
    'maozhaungzhuang.zhuangzhuang',
    'com.maimengtutu.25hours',
    'com.record.Trecken',
    'NinjaVsZombie',
    'SnowHome',
    'StarGuard',
    'jinianday.ImtEvents',
    'com.kekoudeyinshijilu.cn',
    'com.yl.BabyRecord',
    'yinshiben.DietBooks',
    'com.lizhilekechengjilu.cn',
    'com.record.Lesplan',
    'com.fangdonghourseguanli.cn',
    'com.HalloweenMonster.com',
    'com.elegantCow.com',
    'com.xiaomaomiderichang.cn',
    'xueshengben.StudentsBook',
    'yuezhiji.ReadingFeeling',
    'shejishiMeizi.com',
    'com.record.Studienotas',
    'com.yundongyuyinshi.cn',
    'com.work.WorkRecord',
    'com.RescueZookeeper.com',
    'com.pianokechengjilu.cn',
    'com.record.Huisbeheer',
    'com.record.Viagem',
    'com.canland.local.clan',
    'com.record.RikodiCode',
    'dbben.LittleBen',
    'fenshuben.ScoreBook',
    'jfzhan.ScoringStack',
    'com.maojianzaoyinjiance.cn',
    'bfben.SmtNoteScore',
    'com.xiaolideyitian.cn',
    'com.bangzhujianghuakunnanderen.cn',
    'pingrigongzuo.WeekdaysWork',
    'com.fr.FlosRecord',
    'yicaiji.ArtColor',
    'jkangjilu.HealthJiRd',
]

/**
 * 带点(.)budleId的配置。
 * @type {string[]}
 */
export const withDotBundle = [
    'duaiYingerchuang.com',
    'com.jiazimei.com',
    'com.ziniaoku.com',
    'femianbuJinghua.com',
    'com.zidanZheyangsan.com',
    'banshengShouzuo.com',
    'youguaChuju.com',
    'fangsaixiu.com',
    'weiweinvwa.com',
    'com.hexixiongzhen.com',
    'runshachahu.com',
    'gaoguoWeiyang.com',
    'guai.hunanguaiguaisheng.s'
]